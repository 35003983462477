import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { AsyncSelect } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ProductsApi, ReplacementsApi } from 'src/api';

const ReplacementsProductSelect = ({ currentProductId, validator, value, onChange, setLoading, loading }) => {
  const [replacements, setReplacements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const { data } = await ReplacementsApi.getProductReplacements(currentProductId);
        setReplacements(data.products);
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [currentProductId]);

  const replacementsToExcludeIds = useMemo(() => {
    return replacements?.length > 0 ? replacements.map((replacement) => replacement.id) : [];
  }, [replacements]);

  if (isLoading || loading)
    return (
      <div>
        <Skeleton
          width={'100%'}
          height={40}
        />
      </div>
    );

  return (
    <AsyncSelect
      id={'localSelectedProduct'}
      value={value}
      onChange={(value) => {
        onChange(value);
        setLoading(true);
      }}
      apiCallback={ProductsApi.getProducts}
      queryParams={{ ...(replacementsToExcludeIds.length > 0 && { exclude_product_ids: replacementsToExcludeIds }) }}
      replacementsArray={replacements}
      valueKey={'id'}
      labelKey={'name'}
      placeholder={'Wyszukaj zamiennik..'}
      validator={validator}
      rule={'required'}
      productSearch
      isClearable
    />
  );
};

export default ReplacementsProductSelect;
