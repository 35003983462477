import React from 'react';

import ReplacementsTableThumbnail from '../ReplacementsTableThumbnail';

import styles from '../../../../ValuationReplacements.module.scss';

const ReplacementsTable = () => {
  return (
    <div className={styles.table_item_replacements}>
      <ReplacementsTableThumbnail />
      <ReplacementsTableThumbnail />
      <ReplacementsTableThumbnail />
    </div>
  );
};

export default ReplacementsTable;
