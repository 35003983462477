import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Menu, Modal, SaveButton, useIsAdmin, useModalConfirm, usePermissions } from 'components';
import AddProductToList from 'components/layout/AddProductToList';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';

import { refreshCartOrCatalog } from '../../../../actions';
import SupplierThumbnail from '../../../SupplierThumbnail';

import style from './ProductMenu.module.scss';

const ProductMenu = ({ product, selectedCompany, isCatalogView }) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalAddToListOpen, setIsModalAddToListOpen] = useState(false);
  const [canMenageCatalog] = usePermissions([userPermissions.offer_catalog.menage]);

  const deleteProduct = async () => {
    const data = {
      product_id: product.product_id,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      await OfferCatalogApi.deleteProduct(data);
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deletePrice = async (id) => {
    const data = {
      id,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      await OfferCatalogApi.deleteSinglePrice(data);
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteProductHandler = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć wszystkie oferty na ten produkt? Nie będzie on widoczny po usunięciu.',
      handleConfirm: deleteProduct,
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });
  };

  const actions = [
    {
      title: 'Dodaj do listy',
      icon: 'add',
      action: () => setIsModalAddToListOpen(true)
    },
    {
      title: 'Usuń wybraną ofertę',
      icon: 'trash',
      color: 'blue',
      action: () => setIsOpen(true),
      roles: canMenageCatalog ? ['user', 'admin'] : ['admin']
    }
  ];

  isCatalogView &&
    actions.push({
      title: 'Usuń produkt',
      icon: 'trash',
      color: 'red',
      action: deleteProductHandler,
      roles: canMenageCatalog ? ['user', 'admin'] : ['admin']
    });

  return (
    <>
      <Menu
        actions={actions}
        className={style.menu}
      />
      {renderedModalConfirm}
      <Modal
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        title={'Usuń wybraną ofertę'}
      >
        <div className={style.wrapper}>
          {product.prices.map((price) => (
            <SupplierThumbnail
              key={price.id}
              item={price}
              showReplacements
              deletePrice={deletePrice}
            >
              <SaveButton
                type={'delete'}
                onClick={() => deletePrice(price.id)}
              />
            </SupplierThumbnail>
          ))}
        </div>
      </Modal>
      <AddProductToList
        title={`Dodaj "${product.name}" do listy`}
        visible={isModalAddToListOpen}
        onClose={() => setIsModalAddToListOpen(false)}
        productId={product.product_id}
      />
    </>
  );
};

export default ProductMenu;
