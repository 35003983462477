import React from 'react';

import { SaveButton } from 'components';

import OfferWrapper from '../../../../../../../CatalogAndCartController/components/OfferWrapper';
import ProductInfo from '../../../ProductInfo';

import styles from '../../../../ValuationReplacements.module.scss';

const exampleReplacement = {
  name: 'ARKONA Włókno PODWIĄZKA dł. 10cm szer. 2mm światłoutwardzalne włókno formowalne | 1 szt.',
  producer: '3M',
  quantity: 2,
  unit_name: 'op.',
  thumbnail: 'https://dentametrstaging.s3.eu-central-003.backblazeb2.com/images/products/3640/thumbnail-1695815980.jpeg'
};

const ReplacementsTableThumbnail = () => {
  return (
    <div className={styles.table_item_replacement}>
      <div className={styles.table_item_replacement_product}>
        <ProductInfo product={exampleReplacement} />
      </div>
      <div className={styles.table_item_replacement_offers}>
        <div className={styles.table_item_replacement_offer}>
          <OfferWrapper
            item={{ price: 123, supplier_name: 'Marku', color: 'rgb(79, 178, 227)' }}
            isCatalogView={true}
            isReplacementOffer
          />
        </div>
        <div className={styles.table_item_replacement_offer}>
          <OfferWrapper
            item={{ price: 123, supplier_name: 'Marrodent', color: 'rgb(219, 148, 217)' }}
            isCatalogView={true}
          />
        </div>
        <div className={styles.table_item_replacement_offer}>
          <OfferWrapper
            item={{ price: 123, supplier_name: 'Koldental', color: 'rgb(79, 178, 13)' }}
            isCatalogView={true}
          />
        </div>
        <div className={styles.table_item_replacement_offer}>
          <OfferWrapper
            item={{ price: 123, supplier_name: 'Salus', color: 'rgb(112, 45, 12)' }}
            isCatalogView={true}
          />
        </div>
        <div className={styles.table_item_replacement_offer}>
          <OfferWrapper
            item={{ price: 123, supplier_name: 'Marku', color: 'rgb(79, 178, 227)' }}
            isCatalogView={true}
          />
        </div>
        <div className={styles.table_item_replacement_offer}>
          <OfferWrapper
            item={{ price: 123, supplier_name: 'Marrodent', color: 'rgb(219, 148, 217)' }}
            isCatalogView={true}
          />
        </div>
        <div className={styles.table_item_replacement_offer}>
          <OfferWrapper
            item={{ price: 123, supplier_name: 'Koldental', color: 'rgb(79, 178, 13)' }}
            isCatalogView={true}
          />
        </div>
        <div className={styles.table_item_replacement_offer}>
          <OfferWrapper
            item={{ price: 123, supplier_name: 'Salus', color: 'rgb(112, 45, 12)' }}
            isCatalogView={true}
          />
        </div>
      </div>
      <div className={styles.table_item_replacement_button}>
        <SaveButton
          type={'add'}
          onClick={() => console.log('Dodaję!')}
        />
      </div>
    </div>
  );
};

export default ReplacementsTableThumbnail;
