import React from 'react';

import { Accordion } from 'components';

import ValuationReplacementsTable from './components/ValuationReplacementsTable';

import styles from './ValuationReplacements.module.scss';

const ValuationReplacements = () => {
  return (
    <div className={styles.container}>
      <Accordion
        title={'Zamienniki'}
        id={'replacements'}
        extraDeps={[]}
      >
        <div className={styles.container_content}>
          <p>
            Phosfluorescently formulate flexible catalysts for change rather than performance based alignments. Professionally
            pontificate effective human capital and quality value. Synergistically parallel task process-centric strategic theme
            areas with dynamic results. Credibly harness cooperative leadership skills whereas distributed technology. Proactively
            parallel task impactful infomediaries and cross-media testing procedures.
          </p>
          <ValuationReplacementsTable />
        </div>
      </Accordion>
    </div>
  );
};

export default ValuationReplacements;
