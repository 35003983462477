import React from 'react';
import classNames from 'classnames';

import shared from 'src/styles/Shared.module.scss';

import ValuationReplacementsThumbnail from '../ValuationReplacementsThumbnail';

import styles from '../../ValuationReplacements.module.scss';

const ValuationReplacementsTable = () => {
  return (
    <div className={styles.table}>
      <div className={styles.table_wrapper}>
        <ValuationReplacementsThumbnail />
        <ValuationReplacementsThumbnail />
        <ValuationReplacementsThumbnail />
      </div>
    </div>
  );
};

export default ValuationReplacementsTable;
