import React from 'react';

import ProductInfo from '../ProductInfo';
import ReplacementsTable from './components/ReplacementsTable';

import styles from '../../ValuationReplacements.module.scss';

const exampleProduct = {
  name: 'Rękawiczki zielone | 100 szt.',
  producer: '3M',
  quantity: 1,
  unit_name: 'op.',
  thumbnail: 'https://dentametrstaging.s3.eu-central-003.backblazeb2.com/images/products/32933/thumbnail-1683653502.png'
};

const ValuationReplacementsThumbnail = () => {
  return (
    <div className={styles.table_item}>
      <ProductInfo product={exampleProduct} />
      <ReplacementsTable />
    </div>
  );
};

export default ValuationReplacementsThumbnail;
