import React, { useState } from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';

import { Icon, useStateTimeout } from 'components';

import ReplacementsProductSelect from './components/ReplacementsProductSelect';

import style from '../ProductComponents.module.scss';

const ReplacementDetails = (props) => {
  const {
    name,
    producer,
    productReplacement,
    setName,
    setProducer,
    setProductReplacement,
    validator,
    disabled,
    currentProduct,
    removeHandler,
    setLoading,
    loading
  } = props;
  const { product_id } = currentProduct;

  const [localName, setLocalName] = useState('');
  const [localProducer, setLocalProducer] = useState('');
  const [localSelectedProduct, setLocalSelectedProduct] = useState(productReplacement || null);

  useStateTimeout({
    localState: localName,
    setLocalState: setLocalName,
    state: name,
    setState: setName
  });

  useStateTimeout({
    localState: localProducer,
    setLocalState: setLocalProducer,
    state: producer,
    setState: setProducer
  });

  useStateTimeout({
    localState: localSelectedProduct,
    setLocalState: setLocalSelectedProduct,
    state: productReplacement,
    setState: setProductReplacement
  });

  return (
    <div className={classNames(style.wrapper, style.productDetails)}>
      <div className={classNames(style.wrapper, style.replacementDetails)}>
        <div className={classNames(style.wrapper, style.replacementInputs)}>
          <div className={style.header}>
            <h5>Zamiennik</h5>
            <Tooltip
              placement='top'
              title={'Zrezygnuj'}
            >
              <button
                className={style.closeReplacementsFormButton}
                onClick={removeHandler}
              >
                <Icon
                  name='close'
                  size='20'
                />
              </button>
            </Tooltip>
          </div>
          <ReplacementsProductSelect
            currentProductId={product_id}
            validator={validator}
            value={localSelectedProduct}
            onChange={setLocalSelectedProduct}
            setLoading={setLoading}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

{
  /* <Input
  name={'productName'}
  id={`productName-${parent.id}`}
  value={localName}
  onChange={(e) => setLocalName(e.target.value)}
  placeholder={'Nazwa zamiennika'}
  label={isMobile ? 'Nazwa zamiennika' : null}
  validator={validator}
  rule={'required'}
  disabled={disabled}
  wrapperStyle={style.inputName}
/>
<Input
  name={'producer'}
  id={`producer-${parent.id}`}
  value={localProducer}
  onChange={(e) => setLocalProducer(e.target.value)}
  placeholder={'Producent'}
  label={isMobile ? 'Producent' : null}
  validator={validator}
  rule={'required'}
  disabled={disabled}
  wrapperStyle={style.inputProducer}
/> */
}

export default ReplacementDetails;
